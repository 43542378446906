import { useState, useEffect, useCallback } from 'react';
import { env } from '../../constants';
import type { LoginResponse } from '@dx-ui/osc-login';
import { Header, type HeaderProps } from '@dx-ui/osc-header';
import { generateSignupUrl, persistSessionState } from '../../utils';
import { useRouter } from 'next/router';
import { useBrandDetails, useGetMVTSelections, useMetrics, useWrappedRouter } from '../../hooks';
import { useAuth, getGuestFirstName } from '@dx-ui/framework-auth-provider';
import type { Maybe } from '@dx-ui/queries-dx-shop-search-ui';
import { removePrimarySlug, sanitizedSearchUrlParams, enterpriseMenuLinks } from './header-utils';
import { useAppState } from '../../providers/app-provider';
import { useFilterState } from '../../providers/filter-provider';

type SearchHeaderProps = {
  brandCode?: Maybe<string>;
  brandName?: string;
  isFluid?: boolean;
};

export const SearchHeader = ({ brandCode, isFluid }: SearchHeaderProps) => {
  const [user, setUser] = useState<HeaderProps['user']>();
  const metrics = useMetrics();
  const router = useRouter();
  const { safeQueryParams: queryParameters } = useWrappedRouter();
  const { guestInfo, login, logout } = useAuth();
  const { shouldUsePoints, selectedCurrency, sortType } = useAppState();
  const activeFiltersState = useFilterState();

  const {
    isMVTVariant: { b: shouldDisplayEnterpriseMenuLinks },
  } = useGetMVTSelections({
    agentId: 'a-KWCWYvigb37a',
  });

  useEffect(() => {
    setUser(
      guestInfo
        ? {
            name: getGuestFirstName({ guestInfo }),
            honorsTier: guestInfo?.hhonors?.summary?.tierName || '',
            honorsPoints: guestInfo?.hhonors?.summary?.totalPoints || 0,
            honorsPointsFmt: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
            hhonorsNumber: guestInfo?.hhonors?.hhonorsNumber || '',
          }
        : undefined
    );
  }, [guestInfo]);

  const onSignInAttempt: HeaderProps['onSignInAttempt'] = useCallback(
    async (resp: LoginResponse) => {
      persistSessionState({
        shouldUsePoints,
        selectedCurrency,
        sortType,
        activeFiltersState,
      });
      if (resp?.data) {
        await login(resp);
      }
    },
    [activeFiltersState, login, selectedCurrency, shouldUsePoints, sortType]
  );

  const onSignOut: HeaderProps['onSignOut'] = useCallback(async () => {
    metrics.trackSignoutClick();
    await logout().then(() => {
      //TODO:NHCSEARCH-4988 refactor this function to use URL API to remove slugs/create new urls
      const urlWithoutPrimarySlug = removePrimarySlug(window.location.href);
      const sanitizedUrl = sanitizedSearchUrlParams(urlWithoutPrimarySlug);

      return router.push(sanitizedUrl.href);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout, metrics, queryParameters?.primarySlug]);

  const lang = router.locale as string;

  //HACK: PAUL. Actively asking core+ to add this to hotelsummaryoptions.brands array. Should not be held by UI...
  const brand = useBrandDetails({ brandCode: brandCode || 'WW' });

  const forgetInfoUrl = env.PARTNER_FORGET_INFO_LINK.replace('/__LANG__/', `/${lang}/`);

  return (
    <Header
      brand={brand}
      megaNavLinks={shouldDisplayEnterpriseMenuLinks ? enterpriseMenuLinks : undefined}
      isFluid={isFluid}
      loginOptions={{
        options: {
          showCreatePassword: true,
          forgetInfoLink: { url: forgetInfoUrl },
          secureInfoLink: { url: env.GLOBAL_PRIVACY_URL },
          joinLink: { url: generateSignupUrl(), underline: false },
        },
      }}
      onSignInAttempt={onSignInAttempt}
      onSignOut={onSignOut}
      user={user}
      showNewHeader={true}
      languageSelectorOptions={{
        onLanguageSelection: () =>
          persistSessionState({ shouldUsePoints, selectedCurrency, sortType, activeFiltersState }),
        appName: env.APP_NAME,
        locale: lang,
        labelOptions: {
          className: 'text-sm',
        },
        buttonOptions: {
          className: 'text-sm',
        },
      }}
      shouldOpenAccountLinksInNewWindow
    />
  );
};
