import type { HotelAmenityId } from '@dx-ui/queries-dx-shop-search-ui';
import { KEY_AMENITY_INFO_ON_HOTEL_CARD } from '../../../../constants';
import { useGetMVTSelections } from '../../../../hooks';

export type HotelAmenities = { name: string; id: string }; //| undefined;

const KEY_AMENITIES = {
  freeParking: { id: 'freeParking', name: 'Free parking' },
  freeBreakfast: { id: 'freeBreakfast', name: 'Free breakfast' },
  indoorPool: { id: 'indoorPool', name: 'Indoor pool' },
  outdoorPool: { id: 'outdoorPool', name: 'Outdoor pool' },
} as unknown as Record<string, HotelAmenities>;

export const useKeyAmenityInfoInHotelCardMVT = ({
  amenities,
}: {
  amenities: HotelAmenityId[] | undefined;
}) => {
  const {
    isLoaded: isKeyAmenityInfoInHotelCardMVTLoaded,
    isMVTVariant: { b: isKeyAmenityInfoInHotelCard },
  } = useGetMVTSelections({
    agentId: KEY_AMENITY_INFO_ON_HOTEL_CARD.agentId,
  });
  const keyAmenities: HotelAmenities[] = [];
  if (isKeyAmenityInfoInHotelCardMVTLoaded && isKeyAmenityInfoInHotelCard) {
    amenities?.forEach((amenityId) => {
      if (Object.keys(KEY_AMENITIES).includes(amenityId)) {
        keyAmenities.push(KEY_AMENITIES[amenityId] as HotelAmenities);
      }
    });
  }

  return keyAmenities;
};
