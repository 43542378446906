//Agents
export const CHANGE_DEFAULT_SORT_WHEN_USING_POINTS = { agentId: 'a-tTCxboiLp1xh' };
export const RENOVATED_PROPERTY_CHIP = { agentId: 'a-1WB9qz7LcqNO' };
export const WHY_PROPERTY = { agentId: 'a-jJfo0QQjRpXD' };
export const BRAND_FILTER_CATEGORIES_V2 = { agentId: 'a-NHNLF37qpGEx' };
export const REMOVE_FLEXIBLE_DATES = { agentId: 'a-88XVLKrnNI' }; // NHCSEARCH-5088
export const AMENITY_SECTION_RANKING = { agentId: 'a-jZIxq49KNw' }; //NHCSEARCH-5126
export const HIGHEST_AMENITIES_SUGGESTED_FILTERS = { agentId: 'a-GmE1urgBDm' }; //NHCSEARCH-5346
export const BROWSE_BY_AMENITY_TILES = { agentId: 'a-NFgD9WdMdIPd' }; // NHCSEARCH-5262
export const FLEX_DATES_MODAL = { agentId: 'a-PL25UIUrUg' }; //NHCSEARCH-5248
export const RESTYLE_DEFAUlT_LOCATIONS_CONTENT_BLOCKS = { agentId: 'a-y7krXYMt5GOH' }; //NHCSEARCH-5305
export const MAX_POINTS_FILTER = { agentId: 'a-ESpyzzXFur' }; //NHCSEARCH-5310
export const SHOW_MYWAY_BENEFITS = { agentId: 'a-Mw11Sgrtea' }; //NHCSEARCH-5195
export const HDP_ROOMS = { agentId: 'a-zN75Ry4qs3' }; //NHCSEARCH-5306
export const COMPARE_ONBOARDING = { agentId: 'a-ibECGHj2abNq' }; //NHCSEARCH-5449
export const DISPLAY_NUM_OF_AVAILABLE_POINTS = { agentId: 'a-dqaGCve3fDy1' }; // NHCSEARCH-5365
export const FILTERS_CTA_ORDER = { agentId: 'a-1iUqYTuyeK' }; //NHCSEARCH-5432
export const AMENITY_CATEGORIES_REORDER = { agentId: 'a-3je4ALXN9T' }; //NHCSEARCH-5196
export const RECENTLY_VIEWED_CHIP = { agentId: 'a-KjAfCt0wPt3W' }; // NHCSEARCH-5197
export const FLEX_DATES_VERBIAGE = { agentId: 'a-d962rSWS6Y66' }; //NHCSEARCH-5363
export const VERTICAL_SPACE_SAVINGS = { agentId: 'a-xWbHOmL7nuGi' }; //NHCSEARCH-5451
export const AMEX_BANNER_DEFAULT_LOCATIONS = { agentId: 'a-geDi8LFS7fLd' }; //NHCSEARCH-5592
export const KEY_AMENITY_INFO_ON_HOTEL_CARD = { agentId: 'a-z3L3qY0iBD' }; // NHCSEARCH-5622

export const VARIANTS = {
  Default: 'a',
  VariantB: 'b',
  VariantC: 'c',
  VariantD: 'd',
};

export const GOALS = {
  BookingComplete: 'booked-ok',
  FilterEngagement: 'g-filterengagement',
  FlexDatesPageLoad: 'g-4DgGm9x0WU',
  PaymentPageLoad: 'g-ezxfS9StvV',
  RoomsPageLoad: 'g-5ycTw2orHw',
  LocationsPageLoad: 'g-Bnktxc1kfY',
  SearchResultsPageLoad: 'g-0JJz7s9mtH',
  suggestedFilterEngagement: 'g-9GiRp71nci',
  shopHonorsLogin: 'g-b5JxefXddp',
  ZeroResultsCount: 'g-zeroresultscount',

  //Default Locations card and reel engagements
  NearbyDestinationCardEngagement: 'g-ul1xthOX0f',
  PopularDestinationCardEngagement: 'g-aXdeDK6XHj',
  OverallDestinationCardEngagement: 'g-IWoGU9Qp7n',

  //NHCSEARCH-4697 Brand Categories
  BrandsFilterApply: 'g-rBf6w9ia4l',
  BrandsFilterClick: 'g-iVmMvcnCEJ',

  //NHCSEARCH-4783 Default locations Hero and Shop Form Update
  DLFindAHotel: 'g-34ZXagz3zu',

  // NHCSEARCH-5028 Why Property (reused from 4679 Why Brand)
  whyBrandClicked: 'g-PxxunyVcGw',
  whyBrandTotalClicks: 'g-VmdFNCNK8X',

  //NHCSEARCH-5088 Remove Flexible dates
  myFlexibleDatesClick: 'g-lNDlYVzRu9',
  resetDates: 'g-gjmxUjWSsN',

  //NHCSEARCH-5126, NHCSEARCH-5196
  amenitiesFilterApplied: 'g-1xsItW1p6l',

  //NHCSEARCH-5249
  //NHCSEARCH-5622 Key amenity info on hotel-card
  compareHotelsCheckboxSelected: 'g-tJK6XYgfzg',
  compareHotelsCTAClick: 'g-fLXN7qrWyE',
  compareHotelsToggleOn: 'g-6JoBhxn0qO',

  //NHCSEARCH-5262 - Browse by amenity tiles
  amenityTileEngagement: 'g-VtRaOlg45N',
  amenityModalCTAEngagement: 'g-xx9R13xP0B',

  //NHCSEARCH-5248 - Flex Dates Modal on Locations page
  flexDatesModalOpens: 'g-w8sKuGa0aq',
  flexDatesModalTotalOpens: 'g-XoF8I8I0Ym',
  selectDates: 'g-pvZw9Qqqtb',

  //NHCSEARCH-5305 - Restyle default location content blocks
  seoContentBlockLinkClicked: 'g-C0KoORMKNV',
  //NHCSEARCH-5310 - Max Points Filter
  pointsModalOpen: 'g-ILeHaHnLUq',
  pointsFilterApply: 'g-w1izXiWwzr',

  //NHCSEARCH-5306
  hdpRatesClick: 'g-FsogjdwAGF',
  hdpPaymentClick: 'g-sFRW2ocBEh',
  hdpRoomsClick: 'g-iHa1kCBy7q',

  //NHCSEARCH-5432  Filters CTAs order
  allFiltersEngagement: 'g-KdEcBRaC9Q',
  amenitiesFilterEngagement: 'g-IX8nArbk3U',
  priceFilterClick: 'g-FggLQuCasj',

  //NHCSEARCH-5451 Vertical Space Savings
  sortEngagement: 'g-yG33OlhDUQ',
  currencyEngagement: 'g-BgbSyTvidK',

  // NHCSEARCH-5197
  recentlyViewedHotelClick: 'g-hIEQhB57cP',

  // NHCSEARCH-5592 - Amex banner on default locations
  amexModalOpen: 'g-ky7ZdRJrQ9',
  amexModalCTA: 'g-thBL8O15mq',
  amexBannerClick: 'g-MYxlENt8wX',
};
