import { keepPreviousData, useQueries } from '@tanstack/react-query';
import { HotelDocument } from '@dx-ui/queries-dx-shop-search-ui';
import type { HotelQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { getMPAHotelRate } from '../../hotel-card/utils/hotel-mpa-rate-utils';
import {
  getHotelLeadRateInfo,
  type HotelCardData,
} from '../../hotel-card/utils/hotel-lead-rate-utils';
import { useAppState } from '../../../providers/app-provider';
import { useRenovationChip, useWrappedRouter } from '../../../hooks';
import { useCurrencyConversion } from '@dx-ui/osc-currency-converter';
import useShopMultiPropAvail from '../../../hooks/use-shop-multi-prop-avail/use-shop-multi-prop-avail';
import { useTranslation } from 'next-i18next';
import type { HotelBadgeTypes } from '../../hotel-card/hotel-badges';
import { getDistanceFromCenterFmt, type GoogleCoordinate } from '../../../utils';
import { getCompareImage, getParkingType, getRoomTypes } from '../compare-hotel-utils';
import type { HotelInfoAttributes } from '../../hotel-card/utils/hotel-info-utils';
import { isIndividualRoomLimitExceeded } from '../../hotel-card/utils/hotel-info-utils';

export const useGetCompareHotelsInfo = ({
  ctyhocns,
  centerCoordinate,
  displayHotelCount = 0,
  isEnabled,
}: {
  ctyhocns: string[];
  centerCoordinate: GoogleCoordinate | null;
  displayHotelCount?: number;
  isEnabled?: boolean;
}) => {
  const {
    hasConnectingRooms,
    selectedCurrency = '',
    shouldUsePoints = false,
    pageType,
    hasSpecialRate,
  } = useAppState();
  const { safeQueryParams: queryParameters, router } = useWrappedRouter();
  const isDateFlex = queryParameters?.datesFlex || false;
  const { currenciesError, currenciesMap } = useCurrencyConversion();
  const { t } = useTranslation(['hotel-card', 'rate-name-cta', 'locations-page', 'compare-hotels']);
  const { isPointsExplorer, isDateSearch, isDreams } = pageType;
  const numOfChildren =
    queryParameters?.rooms
      .map((room) => room.children)
      .reduce((previous: number, current: number) => previous + current) || 0;

  const hotelsQueryArr = ctyhocns.map((ctyhocn) => {
    const variables = { language: 'en', ctyhocn, ratios: 'threeByTwo' };
    return {
      queryKey: [HotelDocument, variables],
      placeholderData: keepPreviousData,
      retry: 2,
      staleTime: 1000 * 60 * 10,
      enabled: isEnabled,
    };
  });
  const results = useQueries({
    queries: hotelsQueryArr,
  });
  const hotelData = results.map((hotel) => hotel.data as HotelQuery);
  const { renoChipConfig } = useRenovationChip();

  const { hashedData: priceInfoCollection, isLoading: isMPALoading } = useShopMultiPropAvail({
    ctyhocns: ctyhocns.map((ctyhocn) => ctyhocn),
    mpaConstraints: true,
    enabled: router.asPath.includes('/search/'),
  });
  const hotelInfo = hotelData.map((hotelData) => {
    const hotel = hotelData?.hotel;
    const isRenoHotel = renoChipConfig.includes(hotel?.ctyhocn);
    const currentHotelPriceInfo = hotel?.ctyhocn ? priceInfoCollection[hotel.ctyhocn] : undefined;
    const isIndividualRoomsOnly = isIndividualRoomLimitExceeded(
      currentHotelPriceInfo?.statusCode,
      queryParameters
    );
    let hotelRateInfo: HotelInfoAttributes = {};
    if ((!!priceInfoCollection && isDateSearch) || isPointsExplorer || isDateFlex) {
      hotelRateInfo = getMPAHotelRate({
        numOfChildren,
        usePoints: shouldUsePoints,
        isDateFlex,
        hasSpecialRate,
        hotelData: hotel as HotelCardData,
        priceInfo: currentHotelPriceInfo,
        t,
        locale: router.locale ?? 'en',
        pageType,
        ctyhocn: hotel?.ctyhocn,
        queryParameters,
        selectedCurrency,
        hasConnectingRooms,
        isPointsExplorer,
        currenciesError,
        currenciesMap,
        isIndividualRoomsOnly,
      });
    } else if (isDreams) {
      hotelRateInfo = getHotelLeadRateInfo({
        hotelData: hotel as HotelCardData,
        locale: router.locale ?? 'en',
        ctyhocn: hotel?.ctyhocn,
        usePoints: shouldUsePoints,
        selectedCurrency,
        hasConnectingRooms,
        currenciesError,
        currenciesMap,
        pageType,
        t,
        queryParameters,
      });
    }

    const badges: HotelBadgeTypes[] = [];
    if (hotelRateInfo.isNewHotel) badges.push('new');
    if (hotelRateInfo.isSaleHotel) badges.push('sale');
    if (isRenoHotel) badges.push('reno');

    const { distanceFmt: distance } = getDistanceFromCenterFmt(
      t,
      centerCoordinate,
      hotel?.localization?.coordinate,
      router.locale
    );
    const {
      ctyhocn,
      images,
      name,
      tripAdvisorLocationSummary,
      parking,
      amenities: hotelAmenities,
      roomTypes,
    } = hotelData?.hotel ?? {};
    const parkingType = getParkingType(parking, t);
    const rooms = getRoomTypes(roomTypes, t).slice(0, 3);
    const masterImage = getCompareImage(images?.carousel?.[0]);
    const { ctaHref, ctaLabel, messages } = hotelRateInfo ?? {};
    const amenities = hotelAmenities
      ?.filter((amenity) => !amenity.name.includes('parking'))
      ?.slice(0, 4);

    return {
      ctyhocn,
      name,
      tripAdvisorLocationSummary,
      amenities,
      rooms,
      badges,
      distance,
      parkingType,
      masterImage,
      ctaHref,
      ctaLabel,
      messages,
    };
  });
  return {
    isFetching: results.some((result) => result.isFetching),
    isMPALoading,
    hotelInfo: hotelInfo?.slice(0, displayHotelCount),
  };
};
