//NHCSEARCH-5248  Flex Dates Modal

import { useWrappedRouter } from '../../../../hooks';
import type { ShopCalendar } from '@dx-ui/queries-dx-shop-search-ui';
import type { FlexDatesShopAvailCalendar } from '../flex-dates-utils';
import { useAppState } from '../../../../providers/app-provider';
import { useHotel_ShopAvailCalendarQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { DEFAULT_LANGUAGE } from '@dx-ui/utilities-dates';

type FlexDatesInfoProps = {
  arrivalDate?: string;
  ctyhocn: string;
  enableABTest?: boolean;
};
export const useGetFlexDatesInfo = ({
  arrivalDate,
  ctyhocn,
  enableABTest = true,
}: FlexDatesInfoProps) => {
  const { router } = useWrappedRouter();
  const { shouldUsePoints, selectedCurrency } = useAppState();

  const { data: shopAvailCalendarData, isFetching } = useHotel_ShopAvailCalendarQuery(
    {
      language: router.locale || DEFAULT_LANGUAGE,
      ctyhocn,
      input: {
        arrivalDate,
        displayCurrency: selectedCurrency,
        lengthOfStay: 1,
        numAdults: 1,
        numRooms: 1,
        specialRates: { hhonors: shouldUsePoints },
      },
    },
    { enabled: enableABTest && !!arrivalDate && !!ctyhocn }
  );
  const hotelPriceData = shopAvailCalendarData?.hotel?.shopCalendarAvail
    ?.calendars as ShopCalendar[];
  const statusCode = shopAvailCalendarData?.hotel?.shopCalendarAvail?.statusCode;

  const dailyPrice: Record<string, FlexDatesShopAvailCalendar> = {};
  hotelPriceData?.forEach((price) => {
    if (price && price.arrivalDate) dailyPrice[price?.arrivalDate] = price;
  });

  return {
    isFetching,
    hotelPriceData,
    statusCode,
    dailyPrice,
    shouldUsePoints,
  };
};
