import Icon, { AmenityIcons } from '@dx-ui/osc-icon';
import type { HotelAmenities } from './hooks/use-get-key-amenity-info-mvt';
import { useKeyAmenityInfoInHotelCardMVT } from './hooks/use-get-key-amenity-info-mvt';
import type { HotelAmenityId } from '@dx-ui/queries-dx-shop-search-ui';

export const KeyHotelAmenities = ({ amenities }: { amenities: HotelAmenityId[] | undefined }) => {
  const keyAmenities = useKeyAmenityInfoInHotelCardMVT({ amenities });

  return keyAmenities?.length ? (
    <div className="flex flex-wrap gap-1.5 py-1.5">
      {keyAmenities.map((amenity: HotelAmenities) => (
        <div key={amenity.id} className="flex items-center gap-0.5 text-sm">
          <Icon name={AmenityIcons[amenity.id as HotelAmenityId]} size="sm" />
          <span> {amenity?.name}</span>
        </div>
      ))}
    </div>
  ) : null;
};
