import type { PresetChipTypes } from '@dx-ui/osc-chip';
import { Chip, presetFilter } from '@dx-ui/osc-chip';
import { useTranslation } from 'next-i18next';
import { useIsClient } from 'usehooks-ts';
export type HotelBadgeTypes = Extract<PresetChipTypes, 'new' | 'sale'> | 'reno' | 'view';
export const HotelBadges = ({ badges }: { badges: HotelBadgeTypes[] }) => {
  const { t } = useTranslation('hotel-badges');
  const isClient = useIsClient();
  return badges.length > 0 && isClient ? (
    <div className="my-1 flex gap-1">
      {badges
        .filter(
          (badge: HotelBadgeTypes) => badge === 'reno' || badge === 'view' || presetFilter(badge)
        )
        .map((badge: HotelBadgeTypes) => {
          if (badge === 'reno' || badge === 'view') {
            return <Chip key={badge} data-testid="HotelBadge" size="sm" label={t(badge)} />;
          }
          return <Chip key={badge} data-testid="HotelBadge" preset={badge} size="sm" />;
        })}
    </div>
  ) : null;
};
