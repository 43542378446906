import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { DXError } from '@dx-ui/types-graphql';

export const AirportsDocument = {
  operationName: 'airports',
  operationString: `query airports($language: String!, $locationPageUri: String!, $hasLocationPage: Boolean) {
  airports(
    language: $language
    filter: {locationPageUri: $locationPageUri, hasLocationPage: $hasLocationPage}
  ) {
    id
    code
    name
    hasLocationPage
    locationPageUri
    slugName
  }
}`,
  originalOpName: 'airports',
};
export function useAirportsQuery<TData = Types.AirportsQuery, TError = DXError>(
  variables: Types.AirportsQueryVariables,
  options?: Omit<UseQueryOptions<Types.AirportsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.AirportsQuery, TError, TData>({
    queryKey: [AirportsDocument, variables],
    ...options,
  });
}
export function serverSideAirportsQuery(
  queryClient: QueryClient,
  variables: Types.AirportsQueryVariables,
  options?: FetchQueryOptions<Types.AirportsQuery>
) {
  return queryClient.fetchQuery<Types.AirportsQuery>({
    queryKey: [AirportsDocument, variables],
    ...options,
  });
}

export const Brand_ShopAvailOptionsDocument = {
  operationName: 'brand_shopAvailOptions',
  operationString: `query brand_shopAvailOptions($language: String!, $brandCode: String!, $input: ShopAvailOptionsQueryInput) {
  brand(language: $language, brandCode: $brandCode) {
    shopAvailOptions(input: $input) {
      altCorporateAccount {
        pnd
        name
      }
      contentOffer {
        id
        name
      }
    }
  }
}`,
  originalOpName: 'brand_shopAvailOptions',
};
export function useBrand_ShopAvailOptionsQuery<
  TData = Types.Brand_ShopAvailOptionsQuery,
  TError = DXError
>(
  variables: Types.Brand_ShopAvailOptionsQueryVariables,
  options?: Omit<UseQueryOptions<Types.Brand_ShopAvailOptionsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Brand_ShopAvailOptionsQuery, TError, TData>({
    queryKey: [Brand_ShopAvailOptionsDocument, variables],
    ...options,
  });
}
export function serverSideBrand_ShopAvailOptionsQuery(
  queryClient: QueryClient,
  variables: Types.Brand_ShopAvailOptionsQueryVariables,
  options?: FetchQueryOptions<Types.Brand_ShopAvailOptionsQuery>
) {
  return queryClient.fetchQuery<Types.Brand_ShopAvailOptionsQuery>({
    queryKey: [Brand_ShopAvailOptionsDocument, variables],
    ...options,
  });
}

export const BrandsDocument = {
  operationName: 'brands',
  operationString: `query brands($language: String!) {
  brands(language: $language) {
    altSlugs
    canonicalSlug
    code
    formalName
    hasHotels
    isHotelBrand
    name
    url
    isPartnerBrand
  }
}`,
  originalOpName: 'brands',
};
export function useBrandsQuery<TData = Types.BrandsQuery, TError = DXError>(
  variables: Types.BrandsQueryVariables,
  options?: Omit<UseQueryOptions<Types.BrandsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.BrandsQuery, TError, TData>({
    queryKey: [BrandsDocument, variables],
    ...options,
  });
}
export function serverSideBrandsQuery(
  queryClient: QueryClient,
  variables: Types.BrandsQueryVariables,
  options?: FetchQueryOptions<Types.BrandsQuery>
) {
  return queryClient.fetchQuery<Types.BrandsQuery>({
    queryKey: [BrandsDocument, variables],
    ...options,
  });
}

export const FeatureConfigsDocument = {
  operationName: 'featureConfigs',
  operationString: `query featureConfigs($names: [String!]!) {
  featureConfigs(names: $names) {
    name
    config
    conditions
    tag
  }
}`,
  originalOpName: 'featureConfigs',
};
export function useFeatureConfigsQuery<TData = Types.FeatureConfigsQuery, TError = DXError>(
  variables: Types.FeatureConfigsQueryVariables,
  options?: Omit<UseQueryOptions<Types.FeatureConfigsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.FeatureConfigsQuery, TError, TData>({
    queryKey: [FeatureConfigsDocument, variables],
    ...options,
  });
}
export function serverSideFeatureConfigsQuery(
  queryClient: QueryClient,
  variables: Types.FeatureConfigsQueryVariables,
  options?: FetchQueryOptions<Types.FeatureConfigsQuery>
) {
  return queryClient.fetchQuery<Types.FeatureConfigsQuery>({
    queryKey: [FeatureConfigsDocument, variables],
    ...options,
  });
}

export const FeatureToggles_FeatureConfigsDocument = {
  operationName: 'featureToggles_featureConfigs',
  operationString: `query featureToggles_featureConfigs($names: [String!]!) {
  featureToggles(names: $names) {
    _id: name
    name
    enabled
  }
  featureConfigs(names: $names) {
    name
    config
    conditions
    tag
  }
}`,
  originalOpName: 'featureToggles_featureConfigs',
};
export function useFeatureToggles_FeatureConfigsQuery<
  TData = Types.FeatureToggles_FeatureConfigsQuery,
  TError = DXError
>(
  variables: Types.FeatureToggles_FeatureConfigsQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.FeatureToggles_FeatureConfigsQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.FeatureToggles_FeatureConfigsQuery, TError, TData>({
    queryKey: [FeatureToggles_FeatureConfigsDocument, variables],
    ...options,
  });
}
export function serverSideFeatureToggles_FeatureConfigsQuery(
  queryClient: QueryClient,
  variables: Types.FeatureToggles_FeatureConfigsQueryVariables,
  options?: FetchQueryOptions<Types.FeatureToggles_FeatureConfigsQuery>
) {
  return queryClient.fetchQuery<Types.FeatureToggles_FeatureConfigsQuery>({
    queryKey: [FeatureToggles_FeatureConfigsDocument, variables],
    ...options,
  });
}

export const Geocode_HotelSummaryOptionsDocument = {
  operationName: 'geocode_hotelSummaryOptions',
  operationString: `query geocode_hotelSummaryOptions($address: String, $distanceUnit: HotelDistanceUnit, $language: String!, $placeId: String, $queryLimit: Int!, $sessionToken: String) {
  geocode(
    language: $language
    address: $address
    placeId: $placeId
    sessionToken: $sessionToken
  ) {
    match {
      id
      address {
        city
        country
        state
        postalCode
      }
      name
      type
      geometry {
        location {
          latitude
          longitude
        }
        bounds {
          northeast {
            latitude
            longitude
          }
          southwest {
            latitude
            longitude
          }
        }
      }
    }
    hotelSummaryOptions(distanceUnit: $distanceUnit, sortBy: distance) {
      bounds {
        northeast {
          latitude
          longitude
        }
        southwest {
          latitude
          longitude
        }
      }
      amenities {
        id
        name
        hint
      }
      amenityCategories {
        name
        id
        amenityIds
      }
      brands {
        code
        name
      }
      hotels(first: $queryLimit) {
        _id: ctyhocn
        amenityIds
        brandCode
        ctyhocn
        distance
        distanceFmt
        facilityOverview {
          allowAdultsOnly
          homeUrlTemplate
        }
        name
        display {
          open
          openDate
          preOpenMsg
          resEnabled
          resEnabledDate
          treatments
        }
        contactInfo {
          phoneNumber
        }
        address {
          addressLine1
          city
          country
          state
        }
        localization {
          coordinate {
            latitude
            longitude
          }
        }
        images {
          master(ratios: [threeByTwo]) {
            altText
            ratios {
              size
              url
            }
          }
        }
        tripAdvisorLocationSummary {
          numReviews
          ratingFmt(decimal: 1)
          ratingImageUrl
        }
        leadRate {
          hhonors {
            lead {
              dailyRmPointsRate
              dailyRmPointsRateNumFmt: dailyRmPointsRateFmt(hint: number)
              ratePlan {
                ratePlanName @toTitleCase
                ratePlanDesc
              }
            }
            max {
              rateAmount
              rateAmountFmt
              dailyRmPointsRate
              dailyRmPointsRateRoundFmt: dailyRmPointsRateFmt(hint: round)
              dailyRmPointsRateNumFmt: dailyRmPointsRateFmt(hint: number)
              ratePlan {
                ratePlanCode
              }
            }
            min {
              rateAmount(decimal: 1)
              rateAmountFmt
              dailyRmPointsRate
              dailyRmPointsRateRoundFmt: dailyRmPointsRateFmt(hint: round)
              dailyRmPointsRateNumFmt: dailyRmPointsRateFmt(hint: number)
              ratePlan {
                ratePlanCode
              }
            }
          }
        }
      }
    }
    ctyhocnList: hotelSummaryOptions(distanceUnit: $distanceUnit, sortBy: distance) {
      hotelList: hotels(first: 150) {
        ctyhocn
      }
    }
  }
  geocodeEn: geocode(
    language: "en"
    address: $address
    placeId: $placeId
    sessionToken: $sessionToken
  ) {
    match {
      name
    }
  }
}`,
  originalOpName: 'geocode_hotelSummaryOptions',
};
export function useGeocode_HotelSummaryOptionsQuery<
  TData = Types.Geocode_HotelSummaryOptionsQuery,
  TError = DXError
>(
  variables: Types.Geocode_HotelSummaryOptionsQueryVariables,
  options?: Omit<UseQueryOptions<Types.Geocode_HotelSummaryOptionsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Geocode_HotelSummaryOptionsQuery, TError, TData>({
    queryKey: [Geocode_HotelSummaryOptionsDocument, variables],
    ...options,
  });
}
export function serverSideGeocode_HotelSummaryOptionsQuery(
  queryClient: QueryClient,
  variables: Types.Geocode_HotelSummaryOptionsQueryVariables,
  options?: FetchQueryOptions<Types.Geocode_HotelSummaryOptionsQuery>
) {
  return queryClient.fetchQuery<Types.Geocode_HotelSummaryOptionsQuery>({
    queryKey: [Geocode_HotelSummaryOptionsDocument, variables],
    ...options,
  });
}

export const GeocodeDocument = {
  operationName: 'geocode',
  operationString: `query geocode($address: String, $language: String!, $placeId: String, $sessionToken: String) {
  geocode(
    language: $language
    address: $address
    placeId: $placeId
    sessionToken: $sessionToken
  ) {
    match {
      id
      address {
        city
        country
        countryName
        postalCode
        state
        stateName
      }
      name
      placeUri
      type
    }
  }
}`,
  originalOpName: 'geocode',
};
export function useGeocodeQuery<TData = Types.GeocodeQuery, TError = DXError>(
  variables: Types.GeocodeQueryVariables,
  options?: Omit<UseQueryOptions<Types.GeocodeQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GeocodeQuery, TError, TData>({
    queryKey: [GeocodeDocument, variables],
    ...options,
  });
}
export function serverSideGeocodeQuery(
  queryClient: QueryClient,
  variables: Types.GeocodeQueryVariables,
  options?: FetchQueryOptions<Types.GeocodeQuery>
) {
  return queryClient.fetchQuery<Types.GeocodeQuery>({
    queryKey: [GeocodeDocument, variables],
    ...options,
  });
}

export const GeocodePageDocument = {
  operationName: 'geocodePage',
  operationString: `query geocodePage($language: String!, $path: String!, $pageType: GeocodePageType) {
  geocodePage(language: $language, path: $path, pageType: $pageType) {
    location {
      about {
        contentBlocks {
          title(format: text)
          descriptions
        }
      }
      pageInterlinks {
        title
        links {
          name
          uri
        }
      }
    }
  }
}`,
  originalOpName: 'geocodePage',
};
export function useGeocodePageQuery<TData = Types.GeocodePageQuery, TError = DXError>(
  variables: Types.GeocodePageQueryVariables,
  options?: Omit<UseQueryOptions<Types.GeocodePageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GeocodePageQuery, TError, TData>({
    queryKey: [GeocodePageDocument, variables],
    ...options,
  });
}
export function serverSideGeocodePageQuery(
  queryClient: QueryClient,
  variables: Types.GeocodePageQueryVariables,
  options?: FetchQueryOptions<Types.GeocodePageQuery>
) {
  return queryClient.fetchQuery<Types.GeocodePageQuery>({
    queryKey: [GeocodePageDocument, variables],
    ...options,
  });
}

export const Guest_GuestActivitySummaryOptionsDocument = {
  operationName: 'guest_guestActivitySummaryOptions',
  operationString: `query guest_guestActivitySummaryOptions($guestId: BigInt!, $language: String!, $startDate: String!, $endDate: String!, $guestActivityTypes: [GuestActivityType]!) {
  guest(guestId: $guestId, language: $language) {
    activitySummaryOptions(
      input: {startDate: $startDate, endDate: $endDate, guestActivityTypes: $guestActivityTypes}
    ) {
      guestActivitiesSummary {
        brandCode
      }
    }
  }
}`,
  originalOpName: 'guest_guestActivitySummaryOptions',
};
export function useGuest_GuestActivitySummaryOptionsQuery<
  TData = Types.Guest_GuestActivitySummaryOptionsQuery,
  TError = DXError
>(
  variables: Types.Guest_GuestActivitySummaryOptionsQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.Guest_GuestActivitySummaryOptionsQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.Guest_GuestActivitySummaryOptionsQuery, TError, TData>({
    queryKey: [Guest_GuestActivitySummaryOptionsDocument, variables],
    ...options,
  });
}
export function serverSideGuest_GuestActivitySummaryOptionsQuery(
  queryClient: QueryClient,
  variables: Types.Guest_GuestActivitySummaryOptionsQueryVariables,
  options?: FetchQueryOptions<Types.Guest_GuestActivitySummaryOptionsQuery>
) {
  return queryClient.fetchQuery<Types.Guest_GuestActivitySummaryOptionsQuery>({
    queryKey: [Guest_GuestActivitySummaryOptionsDocument, variables],
    ...options,
  });
}

export const Guest_GuestStays_HotelDocument = {
  operationName: 'guest_guestStays_hotel',
  operationString: `query guest_guestStays_hotel($guestId: BigInt!, $language: String!) {
  guest(language: $language, guestId: $guestId) {
    stays(timeframe: future) {
      hotel {
        ctyhocn
      }
      guestBenefits {
        hotelBenefitSummary {
          items {
            selected
            description
          }
        }
      }
    }
    hotelBenefitOptions {
      brandCode
      hotelBenefitSummary {
        items {
          selected
          description
        }
        usFoodAndBeverageHeadline
        nonUSFoodAndBeverageHeadline
      }
    }
  }
}`,
  originalOpName: 'guest_guestStays_hotel',
};
export function useGuest_GuestStays_HotelQuery<
  TData = Types.Guest_GuestStays_HotelQuery,
  TError = DXError
>(
  variables: Types.Guest_GuestStays_HotelQueryVariables,
  options?: Omit<UseQueryOptions<Types.Guest_GuestStays_HotelQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Guest_GuestStays_HotelQuery, TError, TData>({
    queryKey: [Guest_GuestStays_HotelDocument, variables],
    ...options,
  });
}
export function serverSideGuest_GuestStays_HotelQuery(
  queryClient: QueryClient,
  variables: Types.Guest_GuestStays_HotelQueryVariables,
  options?: FetchQueryOptions<Types.Guest_GuestStays_HotelQuery>
) {
  return queryClient.fetchQuery<Types.Guest_GuestStays_HotelQuery>({
    queryKey: [Guest_GuestStays_HotelDocument, variables],
    ...options,
  });
}

export const HotelDocument = {
  operationName: 'hotel',
  operationString: `query hotel($ctyhocn: String!, $language: String!, $ratios: [HotelImageRatioName!], $input: HotelInput) {
  hotel(ctyhocn: $ctyhocn, language: $language, input: $input) {
    __typename
    brandCode
    ctyhocn
    externalResSystem
    facilityOverview {
      allowAdultsOnly
      homeUrlTemplate
      homeUrlPathTemplate
      shortDesc
    }
    name
    display {
      open
      openDate
      preOpenMsg
      resEnabled
      resEnabledDate
      treatments
    }
    contactInfo {
      phoneNumber
    }
    disclaimers {
      desc
      type
    }
    address {
      _id
      addressFmt
      addressLine1
      city
      countryName
      country
      postalCode
      state
      stateName
    }
    amenities {
      id
      name
      hint
    }
    images {
      master {
        altText
        variants {
          size
          url
        }
      }
      carousel(imageVariant: searchPropertyCarousel, ratios: $ratios) {
        altText
        ratios {
          url
          size
        }
        variants {
          size
          url
        }
      }
    }
    localization {
      currencyCode
      coordinate {
        latitude
        longitude
      }
    }
    tripAdvisorLocationSummary {
      numReviews
      ratingFmt(decimal: 1)
      ratingImageUrl
      reviews {
        id
        rating
        helpfulVotes
        ratingImageUrl
        text
        travelDate
        user {
          username
        }
        title
      }
    }
    leadRate {
      lowest {
        feeTransparencyIndicator
        rateAmount(currencyCode: "USD")
        rateAmountFmt(decimal: 0, strategy: ceiling)
        ratePlan {
          ratePlanDesc
          ratePlanName
          ratePlanCode
        }
      }
      hhonors {
        lead {
          dailyRmPointsRate
          dailyRmPointsRateNumFmt: dailyRmPointsRateFmt(hint: number)
          ratePlan {
            ratePlanName @toTitleCase
            ratePlanDesc
          }
        }
        max {
          rateAmount
          rateAmountFmt
          dailyRmPointsRate
          dailyRmPointsRateRoundFmt: dailyRmPointsRateFmt(hint: round)
          dailyRmPointsRateNumFmt: dailyRmPointsRateFmt(hint: number)
          ratePlan {
            ratePlanCode
          }
        }
        min {
          rateAmount
          rateAmountFmt
          dailyRmPointsRate
          dailyRmPointsRateRoundFmt: dailyRmPointsRateFmt(hint: round)
          dailyRmPointsRateNumFmt: dailyRmPointsRateFmt(hint: number)
          ratePlan {
            ratePlanCode
          }
        }
      }
    }
    serviceAmenities(filter: {category_in: poi}) {
      distance
      distanceFmt
      distanceUnit
      name
    }
    roomTypes {
      numBeds
      suite
      features {
        name
      }
    }
    parking {
      hasFreeParking
      hasSelfParking
      selfParkingCharge {
        chargeAmountFmt
      }
      hasValetParking
      valetParkingCharge {
        chargeAmountFmt
      }
    }
  }
}`,
  originalOpName: 'hotel',
};
export function useHotelQuery<TData = Types.HotelQuery, TError = DXError>(
  variables: Types.HotelQueryVariables,
  options?: Omit<UseQueryOptions<Types.HotelQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.HotelQuery, TError, TData>({
    queryKey: [HotelDocument, variables],
    ...options,
  });
}
export function serverSideHotelQuery(
  queryClient: QueryClient,
  variables: Types.HotelQueryVariables,
  options?: FetchQueryOptions<Types.HotelQuery>
) {
  return queryClient.fetchQuery<Types.HotelQuery>({
    queryKey: [HotelDocument, variables],
    ...options,
  });
}

export const HotelQuadrantsDocument = {
  operationName: 'hotelQuadrants',
  operationString: `query hotelQuadrants {
  hotelQuadrants {
    id
    amenityIds
    brands {
      code
      amenityIds
    }
    bounds {
      northeast {
        latitude
        longitude
      }
      southwest {
        latitude
        longitude
      }
    }
    countries {
      code
      states
    }
  }
}`,
  originalOpName: 'hotelQuadrants',
};
export function useHotelQuadrantsQuery<TData = Types.HotelQuadrantsQuery, TError = DXError>(
  variables: Types.HotelQuadrantsQueryVariables | null = null,
  options?: Omit<UseQueryOptions<Types.HotelQuadrantsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.HotelQuadrantsQuery, TError, TData>({
    queryKey: [HotelQuadrantsDocument, variables],
    ...options,
  });
}
export function serverSideHotelQuadrantsQuery(
  queryClient: QueryClient,
  variables: Types.HotelQuadrantsQueryVariables | null = null,
  options?: FetchQueryOptions<Types.HotelQuadrantsQuery>
) {
  return queryClient.fetchQuery<Types.HotelQuadrantsQuery>({
    queryKey: [HotelQuadrantsDocument, variables],
    ...options,
  });
}

export const HotelSummaryOptionsDocument = {
  operationName: 'hotelSummaryOptions',
  operationString: `query hotelSummaryOptions($language: String!, $input: HotelSummaryOptionsInput) {
  hotelSummaryOptions(language: $language, input: $input) {
    hotels {
      _id: ctyhocn
      amenityIds
      brandCode
      ctyhocn
      distance
      distanceFmt
      externalResSystem
      facilityOverview {
        allowAdultsOnly
        homeUrlTemplate
      }
      name
      display {
        open
        openDate
        preOpenMsg
        resEnabled
        resEnabledDate
        treatments
      }
      contactInfo {
        phoneNumber
      }
      disclaimers {
        desc
        type
      }
      address {
        addressLine1
        city
        country
        countryName
        state
        stateName
        _id
      }
      localization {
        currencyCode
        coordinate {
          latitude
          longitude
        }
      }
      images {
        master(ratios: [threeByTwo]) {
          altText
          ratios {
            size
            url
          }
        }
        carousel(ratios: [threeByTwo]) {
          altText
          ratios {
            url
            size
          }
        }
      }
      tripAdvisorLocationSummary {
        numReviews
        rating
        ratingFmt(decimal: 1)
        ratingImageUrl
      }
      leadRate {
        lowest {
          cmaTotalPriceIndicator
          feeTransparencyIndicator
          rateAmount(currencyCode: "USD")
          rateAmountFmt(decimal: 0, strategy: ceiling)
          ratePlanCode
          ratePlan {
            ratePlanName @toTitleCase
            ratePlanDesc
          }
        }
        hhonors {
          lead {
            dailyRmPointsRate
            dailyRmPointsRateNumFmt: dailyRmPointsRateFmt(hint: number)
            ratePlan {
              ratePlanName @toTitleCase
              ratePlanDesc
            }
          }
          max {
            rateAmount
            rateAmountFmt
            dailyRmPointsRate
            dailyRmPointsRateRoundFmt: dailyRmPointsRateFmt(hint: round)
            dailyRmPointsRateNumFmt: dailyRmPointsRateFmt(hint: number)
            ratePlan {
              ratePlanCode
            }
          }
          min {
            rateAmount
            rateAmountFmt
            dailyRmPointsRate
            dailyRmPointsRateRoundFmt: dailyRmPointsRateFmt(hint: round)
            dailyRmPointsRateNumFmt: dailyRmPointsRateFmt(hint: number)
            ratePlan {
              ratePlanCode
            }
          }
        }
      }
    }
  }
}`,
  originalOpName: 'hotelSummaryOptions',
};
export function useHotelSummaryOptionsQuery<
  TData = Types.HotelSummaryOptionsQuery,
  TError = DXError
>(
  variables: Types.HotelSummaryOptionsQueryVariables,
  options?: Omit<UseQueryOptions<Types.HotelSummaryOptionsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.HotelSummaryOptionsQuery, TError, TData>({
    queryKey: [HotelSummaryOptionsDocument, variables],
    ...options,
  });
}
export function serverSideHotelSummaryOptionsQuery(
  queryClient: QueryClient,
  variables: Types.HotelSummaryOptionsQueryVariables,
  options?: FetchQueryOptions<Types.HotelSummaryOptionsQuery>
) {
  return queryClient.fetchQuery<Types.HotelSummaryOptionsQuery>({
    queryKey: [HotelSummaryOptionsDocument, variables],
    ...options,
  });
}

export const HotelSummaryOptions_GeocodePageDocument = {
  operationName: 'hotelSummaryOptions_geocodePage',
  operationString: `query hotelSummaryOptions_geocodePage($language: String!, $path: String!, $queryLimit: Int!, $currencyCode: String!, $distanceUnit: HotelDistanceUnit, $titleFormat: MarkdownFormatType!, $input: HotelSummaryOptionsInput) {
  geocodePage(language: $language, path: $path) {
    location {
      pageInterlinks {
        title
        links {
          name
          uri
        }
      }
      title(format: $titleFormat)
      accessibilityTitle
      meta {
        pageTitle
        description
      }
      name
      brandCode
      category
      uri
      globalBounds
      breadcrumbs {
        uri
        name
      }
      about {
        contentBlocks {
          title(format: text)
          descriptions
          orderedList
          unorderedList
        }
      }
      paths {
        base
      }
      hotelSummaryExtractUrl
    }
    match {
      address {
        city
        country
        countryName
        state
        stateName
      }
      geometry {
        location {
          latitude
          longitude
        }
        bounds {
          northeast {
            latitude
            longitude
          }
          southwest {
            latitude
            longitude
          }
        }
      }
      id
      name
      type
    }
    hotelSummaryOptions(
      distanceUnit: $distanceUnit
      sortBy: distance
      input: $input
    ) {
      _hotels {
        totalSize
      }
      bounds {
        northeast {
          latitude
          longitude
        }
        southwest {
          latitude
          longitude
        }
      }
      amenities {
        id
        name
        hint
      }
      amenityCategories {
        name
        id
        amenityIds
      }
      brands {
        code
        name
      }
      hotels(first: $queryLimit) {
        amenityIds
        brandCode
        ctyhocn
        distance
        distanceFmt
        facilityOverview {
          allowAdultsOnly
          homeUrlTemplate
        }
        name
        contactInfo {
          phoneNumber
        }
        display {
          open
          openDate
          preOpenMsg
          resEnabled
          resEnabledDate
          treatments
        }
        disclaimers {
          desc
          type
        }
        address {
          addressFmt
          addressLine1
          city
          country
          countryName
          postalCode
          state
          stateName
        }
        localization {
          currencyCode
          coordinate {
            latitude
            longitude
          }
        }
        images {
          master(ratios: [threeByTwo]) {
            altText
            ratios {
              size
              url
            }
          }
          carousel(ratios: [threeByTwo]) {
            altText
            ratios {
              url
              size
            }
          }
        }
        leadRate {
          lowest {
            cmaTotalPriceIndicator
            feeTransparencyIndicator
            rateAmount(currencyCode: $currencyCode)
            rateAmountFmt(decimal: 0, strategy: ceiling)
            ratePlanCode
            ratePlan {
              ratePlanName @toTitleCase
              ratePlanDesc
            }
          }
        }
      }
    }
    ctyhocnList: hotelSummaryOptions(distanceUnit: $distanceUnit, sortBy: distance) {
      hotelList: hotels(first: 20) {
        ctyhocn
      }
    }
  }
  geocodePageEn: geocodePage(language: "en", path: $path) {
    match {
      name
    }
  }
}`,
  originalOpName: 'hotelSummaryOptions_geocodePage',
};
export function useHotelSummaryOptions_GeocodePageQuery<
  TData = Types.HotelSummaryOptions_GeocodePageQuery,
  TError = DXError
>(
  variables: Types.HotelSummaryOptions_GeocodePageQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.HotelSummaryOptions_GeocodePageQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.HotelSummaryOptions_GeocodePageQuery, TError, TData>({
    queryKey: [HotelSummaryOptions_GeocodePageDocument, variables],
    ...options,
  });
}
export function serverSideHotelSummaryOptions_GeocodePageQuery(
  queryClient: QueryClient,
  variables: Types.HotelSummaryOptions_GeocodePageQueryVariables,
  options?: FetchQueryOptions<Types.HotelSummaryOptions_GeocodePageQuery>
) {
  return queryClient.fetchQuery<Types.HotelSummaryOptions_GeocodePageQuery>({
    queryKey: [HotelSummaryOptions_GeocodePageDocument, variables],
    ...options,
  });
}

export const Hotel_ShopAvailCalendarDocument = {
  operationName: 'hotel_shopCalendarPropAvail',
  operationString: `query hotel_shopCalendarPropAvail($ctyhocn: String!, $language: String!, $input: ShopCalendarPropAvailQueryInput) {
  hotel(ctyhocn: $ctyhocn, language: $language) {
    ctyhocn
    shopCalendarAvail(input: $input) {
      statusCode
      currencyCode
      notifications {
        subText
        subType
        title
        text
      }
      calendars {
        arrivalDate
        arrivalDateFmt
        departureDate
        departureDateFmt
        roomRate {
          dailyRmPointsRate
          dailyRmPointsRateFmt
          depositAmount
          depositAmountFmt
          numRoomsAvail
          rateAmount
          rateAmountFmt(decimal: 0, strategy: ceiling)
          rateChangeIndicator
          feeTransparencyIndicator
          cmaTotalPriceIndicator
          ratePlan {
            confidentialRates
            ratePlanName
            ratePlanDesc
            specialRateType
            serviceChargesAndTaxesIncluded
          }
          ratePlanCode
          roomTypeCode
        }
      }
    }
  }
}`,
  originalOpName: 'hotel_shopAvailCalendar',
};
export function useHotel_ShopAvailCalendarQuery<
  TData = Types.Hotel_ShopAvailCalendarQuery,
  TError = DXError
>(
  variables: Types.Hotel_ShopAvailCalendarQueryVariables,
  options?: Omit<UseQueryOptions<Types.Hotel_ShopAvailCalendarQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Hotel_ShopAvailCalendarQuery, TError, TData>({
    queryKey: [Hotel_ShopAvailCalendarDocument, variables],
    ...options,
  });
}
export function serverSideHotel_ShopAvailCalendarQuery(
  queryClient: QueryClient,
  variables: Types.Hotel_ShopAvailCalendarQueryVariables,
  options?: FetchQueryOptions<Types.Hotel_ShopAvailCalendarQuery>
) {
  return queryClient.fetchQuery<Types.Hotel_ShopAvailCalendarQuery>({
    queryKey: [Hotel_ShopAvailCalendarDocument, variables],
    ...options,
  });
}

export const Hotel_ShopPropAvailDocument = {
  operationName: 'hotel_shopPropAvail',
  operationString: `query hotel_shopPropAvail($arrivalDate: String!, $ctyhocn: String!, $departureDate: String!, $language: String!, $guestLocationCountry: String, $numAdults: Int!, $numChildren: Int!, $numRooms: Int!, $displayCurrency: String, $guestId: BigInt, $specialRates: ShopSpecialRateInput, $rateCategoryTokens: [String], $selectedRoomRateCodes: [ShopRoomRateCodeInput!], $ratePlanCodes: [String], $cacheId: String, $knownGuest: Boolean, $modifyingReservation: Boolean, $childAges: [Int], $adjoiningRoomStay: Boolean, $programAccountId: BigInt, $roomTypeSortInput: [ShopRoomTypeSortInput!]) {
  hotel(ctyhocn: $ctyhocn, language: $language) {
    ctyhocn
    shopAvail(
      cacheId: $cacheId
      input: {guestLocationCountry: $guestLocationCountry, arrivalDate: $arrivalDate, departureDate: $departureDate, displayCurrency: $displayCurrency, numAdults: $numAdults, numChildren: $numChildren, numRooms: $numRooms, guestId: $guestId, specialRates: $specialRates, rateCategoryTokens: $rateCategoryTokens, selectedRoomRateCodes: $selectedRoomRateCodes, ratePlanCodes: $ratePlanCodes, knownGuest: $knownGuest, modifyingReservation: $modifyingReservation, childAges: $childAges, adjoiningRoomStay: $adjoiningRoomStay, programAccountId: $programAccountId}
    ) {
      statusCode
      summary {
        specialRates {
          specialRateType
          roomCount
        }
        requestedRates {
          ratePlanCode
          ratePlanName
          roomCount
        }
      }
      notifications {
        subText
        subType
        title
        text
      }
      addOnsAvailable
      currencyCode
      roomTypes(sort: $roomTypeSortInput) {
        roomTypeCode
        adaAccessibleRoom
        numBeds
        roomTypeName
        roomTypeDesc
        roomOccupancy
        executive
        suite
        code: roomTypeCode
        name: roomTypeName
        adjoiningRoom
        thumbnail: carousel(first: 1) {
          _id
          altText
          variants {
            size
            url
          }
        }
        quickBookRate {
          cashRatePlan
          roomTypeCode
          rateAmount
          rateAmountUSD: rateAmount(currencyCode: "USD")
          rateChangeIndicator
          feeTransparencyIndicator
          cmaTotalPriceIndicator
          ratePlanCode
          rateAmountFmt(decimal: 0, strategy: ceiling)
          roomTypeCode
          amountAfterTaxFmt(decimal: 0, strategy: ceiling)
          fullAmountAfterTax: amountAfterTaxFmt
          ratePlan {
            commissionable
            confidentialRates
            ratePlanName
            specialRateType
            hhonorsMembershipRequired
            redemptionType
            serviceChargesAndTaxesIncluded
          }
          serviceChargeDetails
          pointDetails(perNight: true) {
            pointsRate
            pointsRateFmt
          }
        }
        moreRatesFromRate {
          rateChangeIndicator
          feeTransparencyIndicator
          cmaTotalPriceIndicator
          roomTypeCode
          rateAmount
          rateAmountFmt(decimal: 0, strategy: ceiling)
          rateAmountUSD: rateAmount(currencyCode: "USD")
          amountAfterTaxFmt(decimal: 0, strategy: ceiling)
          fullAmountAfterTax: amountAfterTaxFmt
          serviceChargeDetails
          ratePlanCode
          ratePlan {
            confidentialRates
            serviceChargesAndTaxesIncluded
          }
        }
        bookNowRate {
          roomTypeCode
          rateAmount
          rateChangeIndicator
          feeTransparencyIndicator
          cmaTotalPriceIndicator
          ratePlanCode
          rateAmountFmt(decimal: 0, strategy: ceiling)
          amountAfterTaxFmt(decimal: 0, strategy: ceiling)
          fullAmountAfterTax: amountAfterTaxFmt
          roomTypeCode
          ratePlan {
            commissionable
            confidentialRates
            ratePlanName
            specialRateType
            hhonorsMembershipRequired
            disclaimer {
              diamond48
            }
            serviceChargesAndTaxesIncluded
          }
          serviceChargeDetails
        }
        redemptionRoomRates(first: 1) {
          rateChangeIndicator
          pointDetails(perNight: true) {
            pointsRate
            pointsRateFmt
          }
          sufficientPoints
          pamEligibleRoomRate {
            ratePlan {
              ratePlanCode
              rateCategoryToken
              redemptionType
            }
            roomTypeCode
            sufficientPoints
          }
        }
      }
      lowestPointsInc
    }
  }
}`,
  originalOpName: 'hotel_shopPropAvail',
};
export function useHotel_ShopPropAvailQuery<
  TData = Types.Hotel_ShopPropAvailQuery,
  TError = DXError
>(
  variables: Types.Hotel_ShopPropAvailQueryVariables,
  options?: Omit<UseQueryOptions<Types.Hotel_ShopPropAvailQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Hotel_ShopPropAvailQuery, TError, TData>({
    queryKey: [Hotel_ShopPropAvailDocument, variables],
    ...options,
  });
}
export function serverSideHotel_ShopPropAvailQuery(
  queryClient: QueryClient,
  variables: Types.Hotel_ShopPropAvailQueryVariables,
  options?: FetchQueryOptions<Types.Hotel_ShopPropAvailQuery>
) {
  return queryClient.fetchQuery<Types.Hotel_ShopPropAvailQuery>({
    queryKey: [Hotel_ShopPropAvailDocument, variables],
    ...options,
  });
}

export const AllRegionsDocument = {
  operationName: 'regions',
  operationString: `query regions($language: String!) {
  na: regions(
    language: $language
    containsHotels: true
    filter: {htmlSiteMap: true, name: "North America"}
  ) {
    name
    locationPageUri
    countries {
      code
      name
      locationPageUri
      displayName
      states(sort: {by: name, order: asc}) {
        name
        locationPageUri
      }
      cities(sort: {by: name, order: asc}, onlyIfNoStates: true) {
        name
        locationPageUri
      }
    }
  }
  others: regions(
    language: $language
    containsHotels: true
    filter: {htmlSiteMap: true, name_not: "North America"}
  ) {
    name
    locationPageUri
    countries(sort: {by: name, order: asc}) {
      code
      name
      locationPageUri
      displayName
      states {
        name
        locationPageUri
      }
    }
  }
}`,
  originalOpName: 'allRegions',
};
export function useAllRegionsQuery<TData = Types.AllRegionsQuery, TError = DXError>(
  variables: Types.AllRegionsQueryVariables,
  options?: Omit<UseQueryOptions<Types.AllRegionsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.AllRegionsQuery, TError, TData>({
    queryKey: [AllRegionsDocument, variables],
    ...options,
  });
}
export function serverSideAllRegionsQuery(
  queryClient: QueryClient,
  variables: Types.AllRegionsQueryVariables,
  options?: FetchQueryOptions<Types.AllRegionsQuery>
) {
  return queryClient.fetchQuery<Types.AllRegionsQuery>({
    queryKey: [AllRegionsDocument, variables],
    ...options,
  });
}

export const ShopMultiPropAvailPointsDocument = {
  operationName: 'shopMultiPropAvail',
  operationString: `query shopMultiPropAvail($ctyhocns: [String!], $language: String!, $input: ShopMultiPropAvailQueryInput!) {
  shopMultiPropAvail(input: $input, language: $language, ctyhocns: $ctyhocns) {
    ageBasedPricing
    ctyhocn
    currencyCode
    statusCode
    statusMessage
    lengthOfStay
    notifications {
      subType
      text
      type
    }
    summary {
      hhonors {
        dailyRmPointsRate
        dailyRmPointsRateFmt
        rateChangeIndicator
        ratePlan {
          ratePlanName @toTitleCase
        }
      }
      lowest {
        cmaTotalPriceIndicator
        feeTransparencyIndicator
        rateAmountFmt(strategy: ceiling, decimal: 0)
        rateAmount(currencyCode: "USD")
        ratePlanCode
        rateChangeIndicator
        ratePlan {
          attributes
          ratePlanName @toTitleCase
          specialRateType
          confidentialRates
        }
        amountAfterTax(currencyCode: "USD")
        amountAfterTaxFmt(decimal: 0, strategy: ceiling)
      }
      status {
        type
      }
    }
  }
}`,
  originalOpName: 'shopMultiPropAvailPoints',
};
export function useShopMultiPropAvailPointsQuery<
  TData = Types.ShopMultiPropAvailPointsQuery,
  TError = DXError
>(
  variables: Types.ShopMultiPropAvailPointsQueryVariables,
  options?: Omit<UseQueryOptions<Types.ShopMultiPropAvailPointsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.ShopMultiPropAvailPointsQuery, TError, TData>({
    queryKey: [ShopMultiPropAvailPointsDocument, variables],
    ...options,
  });
}
export function serverSideShopMultiPropAvailPointsQuery(
  queryClient: QueryClient,
  variables: Types.ShopMultiPropAvailPointsQueryVariables,
  options?: FetchQueryOptions<Types.ShopMultiPropAvailPointsQuery>
) {
  return queryClient.fetchQuery<Types.ShopMultiPropAvailPointsQuery>({
    queryKey: [ShopMultiPropAvailPointsDocument, variables],
    ...options,
  });
}

export const ShopMultiPropAvailDocument = {
  operationName: 'shopMultiPropAvail',
  operationString: `query shopMultiPropAvail($ctyhocns: [String!], $language: String!, $input: ShopMultiPropAvailQueryInput!) {
  shopMultiPropAvail(input: $input, language: $language, ctyhocns: $ctyhocns) {
    ageBasedPricing
    ctyhocn
    currencyCode
    statusCode
    statusMessage
    lengthOfStay
    notifications {
      subType
      text
      type
    }
    summary {
      lowest {
        cmaTotalPriceIndicator
        feeTransparencyIndicator
        rateAmountFmt(strategy: ceiling, decimal: 0)
        rateAmount(currencyCode: "USD")
        ratePlanCode
        rateChangeIndicator
        ratePlan {
          attributes
          ratePlanName @toTitleCase
          specialRateType
          confidentialRates
        }
        amountAfterTax(currencyCode: "USD")
        amountAfterTaxFmt(decimal: 0, strategy: ceiling)
      }
      status {
        type
      }
    }
  }
}`,
  originalOpName: 'shopMultiPropAvail',
};
export function useShopMultiPropAvailQuery<TData = Types.ShopMultiPropAvailQuery, TError = DXError>(
  variables: Types.ShopMultiPropAvailQueryVariables,
  options?: Omit<UseQueryOptions<Types.ShopMultiPropAvailQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.ShopMultiPropAvailQuery, TError, TData>({
    queryKey: [ShopMultiPropAvailDocument, variables],
    ...options,
  });
}
export function serverSideShopMultiPropAvailQuery(
  queryClient: QueryClient,
  variables: Types.ShopMultiPropAvailQueryVariables,
  options?: FetchQueryOptions<Types.ShopMultiPropAvailQuery>
) {
  return queryClient.fetchQuery<Types.ShopMultiPropAvailQuery>({
    queryKey: [ShopMultiPropAvailDocument, variables],
    ...options,
  });
}

export const TopMarketPlacesDocument = {
  operationName: 'topMarketPlaces',
  operationString: `query topMarketPlaces($input: TopMarketPlaceQueryInput, $language: String!) {
  topMarketPlaces(input: $input, language: $language) {
    headline
    images {
      altText
      image {
        originalId
        originalUrl
        variants {
          topMarket {
            boxHeight
            boxWidth
            cdnLink
            height
            id
            left
            top
            size
            width
          }
        }
      }
    }
    links {
      adaDescription
      isNewWindow
      linkLabel
      linkUrl
    }
    longDescription
    name
    shortDescription
  }
}`,
  originalOpName: 'topMarketPlaces',
};
export function useTopMarketPlacesQuery<TData = Types.TopMarketPlacesQuery, TError = DXError>(
  variables: Types.TopMarketPlacesQueryVariables,
  options?: Omit<UseQueryOptions<Types.TopMarketPlacesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.TopMarketPlacesQuery, TError, TData>({
    queryKey: [TopMarketPlacesDocument, variables],
    ...options,
  });
}
export function serverSideTopMarketPlacesQuery(
  queryClient: QueryClient,
  variables: Types.TopMarketPlacesQueryVariables,
  options?: FetchQueryOptions<Types.TopMarketPlacesQuery>
) {
  return queryClient.fetchQuery<Types.TopMarketPlacesQuery>({
    queryKey: [TopMarketPlacesDocument, variables],
    ...options,
  });
}

export const TopPromoPlacesDocument = {
  operationName: 'topPromoPlaces',
  operationString: `query topPromoPlaces($language: String!) {
  topPromoPlaces(language: $language) {
    headline
    links {
      adaDescription
      linkLabel
      linkUrl
    }
    longDescription
    name
    shortDescription
    images {
      altText
      image {
        originalUrl
        variants {
          fullWidth {
            boxHeight
            boxWidth
            cdnLink
            height
            id
            left
            top
            size
            width
          }
        }
      }
    }
  }
}`,
  originalOpName: 'topPromoPlaces',
};
export function useTopPromoPlacesQuery<TData = Types.TopPromoPlacesQuery, TError = DXError>(
  variables: Types.TopPromoPlacesQueryVariables,
  options?: Omit<UseQueryOptions<Types.TopPromoPlacesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.TopPromoPlacesQuery, TError, TData>({
    queryKey: [TopPromoPlacesDocument, variables],
    ...options,
  });
}
export function serverSideTopPromoPlacesQuery(
  queryClient: QueryClient,
  variables: Types.TopPromoPlacesQueryVariables,
  options?: FetchQueryOptions<Types.TopPromoPlacesQuery>
) {
  return queryClient.fetchQuery<Types.TopPromoPlacesQuery>({
    queryKey: [TopPromoPlacesDocument, variables],
    ...options,
  });
}
