import { RECENTLY_VIEWED_CHIP } from '../constants';

import { useLocalStorage } from 'usehooks-ts';
import { useGetMVTSelections } from './use-get-mvt-selections';
import { useAppState } from '../providers/app-provider';

const REFRESH_TTL_2WEEK = 604800 * 2 * 1000;

export type RecentlyViewedHotel = {
  ctyhocn: string;
  expireDate: number;
};

export function useRecentlyViewed(ctyhocn: string) {
  const [recentlyViewed, setRecentlyViewed] = useLocalStorage<Record<string, RecentlyViewedHotel>>(
    `RecentlyViewed`,
    {},
    {
      initializeWithValue: false,
      serializer: (instance) => {
        if (!instance) {
          return JSON.stringify({
            [ctyhocn]: {
              ctyhocn,
              expireDate: new Date().getTime() + REFRESH_TTL_2WEEK,
            },
          });
        } else {
          return JSON.stringify({
            ...instance,
            [ctyhocn]: {
              ctyhocn,
              expireDate: new Date().getTime() + REFRESH_TTL_2WEEK,
            },
          });
        }
      },
    }
  );

  return [recentlyViewed, setRecentlyViewed] as const;
}

export const useRecentlyViewedMVT = (ctyhocn: string) => {
  const [recentlyViewed, setRecentlyViewed] = useRecentlyViewed(ctyhocn);
  const { hotelsInBounds } = useAppState();

  const hotelsInBoundsSorted = Object.keys(hotelsInBounds)?.sort();
  const recentlyViewedCtyhocns = Object.keys(recentlyViewed).sort();
  const isRecentlyViewedInBounds = hotelsInBoundsSorted.some((hotelCtyhocn) =>
    recentlyViewedCtyhocns.includes(hotelCtyhocn)
  );
  const isVisitRecent =
    recentlyViewed[ctyhocn] &&
    new Date(recentlyViewed[ctyhocn]?.expireDate).getTime() - new Date().getTime() > 0;

  const enableTest = isRecentlyViewedInBounds && isVisitRecent;

  const {
    isLoaded: isRecentlyViewedTestLoaded,
    isMVTVariant: { a: isRecentlyViewedTestVarA, b: isRecentlyViewedTestVarB },
  } = useGetMVTSelections({
    agentId: RECENTLY_VIEWED_CHIP.agentId,
    isProvisional: enableTest,
  });
  const isRecentlyViewedTestEnabled =
    isRecentlyViewedTestLoaded && (isRecentlyViewedTestVarA || isRecentlyViewedTestVarB);
  const showRecentlyViewedChip =
    isRecentlyViewedTestLoaded && isRecentlyViewedTestVarB && isVisitRecent;
  const applyRecentlyViewedGoal = isRecentlyViewedTestEnabled && isVisitRecent;

  return {
    applyRecentlyViewedGoal,
    recentlyViewed,
    setRecentlyViewed,
    showRecentlyViewedChip,
  };
};
