import type { Link } from '@dx-ui/osc-link';
import { sendReward } from '@dx-ui/framework-conductrics';
import { OHW_SEARCH_PRIMARYSLUGS } from '../../constants';
import type { TFunction } from 'i18next';
import type { TDrawerItem } from '@dx-ui/osc-header';
import {
  HGV_MEMBER_PROGRAM_LINKS,
  TMTP_ELIGIBLE_TEAM_MEMBER_PROGRAM_LINKS,
  TMTP_NON_TEAM_MEMBER_PROGRAM_LINKS,
} from './header-constants';
import { logError } from '@dx-ui/framework-logger';

export function getAdditionalLinks(
  isTMTPTeamMember: boolean | undefined,
  isTPHMember: boolean | undefined,
  isTMTPEligibleMember: boolean | undefined,
  t: TFunction<'header'>,
  isHGVOwner: boolean | undefined
) {
  const additionalLinks: Link[] = [];
  if (isTMTPTeamMember || isTMTPEligibleMember || isTPHMember)
    additionalLinks.push(...TMTP_NON_TEAM_MEMBER_PROGRAM_LINKS(t));
  if (isHGVOwner) additionalLinks.push(...HGV_MEMBER_PROGRAM_LINKS(t));
  if (isTMTPTeamMember && !isTMTPEligibleMember)
    additionalLinks.push(...TMTP_ELIGIBLE_TEAM_MEMBER_PROGRAM_LINKS(t));
  return additionalLinks;
}

export const removePrimarySlug = (url: string) => {
  try {
    const urlObj = new URL(url);
    const filteredPath = urlObj.pathname
      .split('/')
      .filter((primarySlug) => !OHW_SEARCH_PRIMARYSLUGS.includes(primarySlug))
      .join('/');
    urlObj.pathname = filteredPath;
    return urlObj.toString();
  } catch (error) {
    if (error instanceof Error || typeof error === 'string')
      logError('Search_UI Header', error, 'Invalid URL');
    return url;
  }
};
const AUTHENTICATED_ONLY_PARAMS = [
  'smbRate',
  'employeeRate',
  'ownerVIPRate',
  'friendsAndFamilyRate',
  'ownerHGVRate',
];
export const hasAuthenticatedParam = (url: string) => {
  let hasParam = false;
  AUTHENTICATED_ONLY_PARAMS.forEach((param) => {
    if (url.includes(`${param}=`)) hasParam = true;
  });
  return hasParam;
};

export const hasPrimarySlug = (url: string) => {
  let hasSlug = false;
  OHW_SEARCH_PRIMARYSLUGS.forEach((param) => {
    if (url.includes(`/${param}/`)) hasSlug = true;
  });
  return hasSlug;
};
export const sanitizedSearchUrlParams = (originalUrl: string) => {
  const url = new URL(originalUrl);
  AUTHENTICATED_ONLY_PARAMS.forEach((param) => {
    url.searchParams.delete(param);
  });
  return url;
};

export const enterpriseMenuLinks: TDrawerItem[] = [
  {
    label: 'Hotels & Resorts',
    link: {
      url: '',
      isNewWindow: false,
      showNewWindowIcon: false,
    },
    subMenu: [
      {
        label: 'Locations',
        link: {
          url: 'https://www.hilton.com/en/locations/',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
      {
        label: 'Brands',
        link: {
          url: 'https://www.hilton.com/en/brands/',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
      {
        label: 'Travel Inspiration',
        link: {
          url: 'https://www.hilton.com/en/travel/',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
      {
        label: 'All-Inclusive',
        link: {
          url: 'https://www.hilton.com/en/all-inclusive/',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
      {
        label: 'Resorts',
        link: {
          url: 'https://www.hilton.com/en/resorts/',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
      {
        label: 'Hilton Getaways',
        link: {
          url: 'https://www.hiltongetaways.com/',
          isNewWindow: true,
          showNewWindowIcon: true,
        },
      },
    ],
  },
  {
    label: 'Hilton Honors',
    link: {
      url: '',
      isNewWindow: false,
      showNewWindowIcon: false,
    },
    subMenu: [
      {
        label: 'About Hilton Honors',
        link: {
          url: 'https://www.hilton.com/en/hilton-honors/',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
      {
        label: 'Join Now',
        link: {
          url: 'https://www.hilton.com/en/hilton-honors/join/?ocode=JHTNW',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
      {
        label: 'Member Benefits',
        link: {
          url: 'https://www.hilton.com/en/hilton-honors/member-benefits/',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
      {
        label: 'Hilton Honors Points',
        link: {
          url: 'https://www.hilton.com/en/hilton-honors/points/',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
    ],
  },
  {
    label: 'Offers',
    link: {
      url: 'https://www.hilton.com/en/offers/',
      isNewWindow: false,
      showNewWindowIcon: false,
    },
    subMenu: [],
  },
  {
    label: 'Meetings & Events',
    link: {
      url: '',
      isNewWindow: false,
      showNewWindowIcon: false,
    },
    subMenu: [
      {
        label: 'All Events',
        link: {
          url: 'https://www.hilton.com/en/events/',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
      {
        label: 'Group Travel',
        link: {
          url: 'https://www.hilton.com/en/events/groups/',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
      {
        label: 'Meetings & Conferences',
        link: {
          url: 'https://www.test.hilton.com/en/events/meetings/',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
      {
        label: 'Weddings',
        link: {
          url: 'https://www.hilton.com/en/events/weddings/',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
      {
        label: 'Celebrations',
        link: {
          url: 'https://www.hilton.com/en/events/celebrations/',
          isNewWindow: false,
          showNewWindowIcon: false,
        },
      },
    ],
  },
  {
    label: 'Credit Cards',
    link: {
      url: 'https://www.hilton.com/en/hilton-honors/credit-cards/',
      isNewWindow: false,
      showNewWindowIcon: false,
    },
    onClick: () => {
      sendReward('g-credit-card-click');
    },
    subMenu: [],
  },
  {
    label: 'Hilton Grand Vacations',
    link: {
      url: 'https://www.hiltongrandvacations.com/en/discover-membership/the-hgv-difference',
      isNewWindow: true,
      showNewWindowIcon: true,
    },
    subMenu: [],
  },
  {
    label: 'Hilton for Business',
    link: {
      url: 'https://www.hilton.com/en/p/hilton-for-business/',
      isNewWindow: false,
      showNewWindowIcon: false,
    },
    subMenu: [],
  },
];
